export const profileConstans = {
    PROFILE_IN: 'PROFILE_IN',
    PROFILE_OUT: 'PROFILE_OUT',

    PROFILE_SINGLE_REQUEST: 'PROFILE_SINGLE_REQUEST',
    PROFILE_SINGLE_SUCCESS: 'PROFILE_SINGLE_SUCCESS',
    PROFILE_SINGLE_FAILURE: 'PROFILE_SINGLE_FAILURE',

    PROFILE_CONTACTS_REQUEST: 'PROFILE_CONTACTS_REQUEST',
    PROFILE_CONTACTS_SUCCESS: 'PROFILE_CONTACTS_SUCCESS',
    PROFILE_CONTACTS_FAILURE: 'PROFILE_CONTACTS_FAILURE',


}