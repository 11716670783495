
// Agencies Constants 
export const agenciesConstants = {
    GET_ALL_AGENCIES_REQUEST: 'GET_ALL_AGENCIES_REQUEST',
    GET_ALL_AGENCIES_SUCCESS: 'GET_ALL_AGENCIES_SUCCESS',
    GET_ALL_AGENCIES_FAILURE: 'GET_ALL_AGENCIES_FAILURE',

    GET_AGENCIES_PAGINATE_SUCCESS: 'GET_AGENCIES_PAGINATE_SUCCESS',

    CREATE_AGENCY: 'CREATE_AGENCY',
    CREATE_FAILURE: 'CREATE_FAILURE',

    UPDATE_AGENCY: 'UPDATE_AGENCY',
    UPDATE_AGENCY_IMAGE: 'UPDATE_AGENCY_IMAGE',

    AGENCY_MODAL_OPEN: 'AGENCY_MODAL_OPEN',
    AGENCY_MODAL_CLOSE: 'AGENCY_MODAL_CLOSE',

    AGENCY_EDIT: 'AGENCY_EDIT',
    AGENCY_EDIT_FAILURE: 'AGENCY_EDIT_FAILURE',
}