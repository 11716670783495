// Candidate Constants
export const candidateConstants = {

    GET_ALL_CANDIDATES_REQUEST: 'GET_ALL_CANDIDATES_REQUEST',
    GET_ALL_CANDIDATES_SUCCESS: 'GET_ALL_CANDIDATES_SUCCESS',
    GET_ALL_CANDIDATES_FAILURE: 'GET_ALL_CANDIDATES_FAILURE',

    CREATE_CANDIDATE: 'CREATE_CANDIDATE',
    CREATE_CANDIDATE_FAILURE: 'CREATE_CANDIDATE_FAILURE',


    CANDIDATE_MODAL_OPEN: 'CANDIDATE_MODAL_OPEN',
    CANDIDATE_MODAL_CLOSE: 'CANDIDATE_MODAL_CLOSE',

    UPDATE_CANDIDATE: 'UPDATE_CANDIDATE',
    UPDATE_CANDIDATE_FAILURE: 'UPDATE_CANDIDATE_FAILURE',

    UPDATE_CANDIDATE_IMG: 'UPDATE_CANDIDATE_IMG',
    UPDATE_CANDIDATE_IMG_FAILURE: 'UPDATE_CANDIDATE_IMG_FAILURE',

    UPDATE_CANDIDATE_RESUME: 'UPDATE_CANDIDATE_RESUME',
    UPDATE_CANDIDATE_RESUME_FAILURE: 'UPDATE_CANDIDATE_RESUME_FAILURE',

    CANDIDATE_DETAILS_IN: 'CANDIDATE_DETAILS_IN',
    CANDIDATE_DETAILS_OUT: 'CANDIDATE_DETAILS_OUT',

    RESUME_IN: 'RESUME_IN',
    RESUME_OUT: 'RESUME_OUT',

    GET_ALL_CANDIDATES_REQUEST_NO_PAGINATE: 'GET_ALL_CANDIDATES_REQUEST_NO_PAGINATE',
    GET_ALL_CANDIDATES_SUCCESS_NO_PAGINATE: 'GET_ALL_CANDIDATES_SUCCESS_NO_PAGINATE',

}