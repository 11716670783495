export const jobConstants = {
    GET_ALL_JOB_REQUEST: 'GET_ALL_JOB_REQUEST',
    GET_JOBS_PAGINATE_SUCCESS: 'GET_JOBS_PAGINATE_SUCCESS',
    GET_ALL_JOB_FAILURE: 'GET_ALL_JOB_FAILURE',

    GET_SINGLE_JOB_REQUEST: 'GET_SINGLE_JOB_REQUEST',
    GET_SINGLE_JOB_SUCCESS: 'GET_SINGLE_JOB_SUCCESS',

    CREATE_JOB: 'CREATE_JOB',
    CREATE_JOB_FAILURE: 'CREATE_JOB_FAILURE',

    UPDATE_JOB: 'UPDATE_JOB',
    UPDATE_JOB_FAILURE: 'UPDATE_JOB_FAILURE',

    JOB_CREATE_UPDATE_MODAL_OPEN: 'JOB_CREATE_UPDATE_MODAL_OPEN',
    JOB_CREATE_UPDATE_MODAL_CLOSE: 'JOB_CREATE_UPDATE_MODAL_CLOSE',

    JOB_APPLY_MODAL_OPEN: 'JOB_APPLY_MODAL_OPEN',
    JOB_APPLY_MODAL_CLOSE: 'JOB_APPLY_MODAL_CLOSE',


    OPPORTUNITIES_IN: 'OPPORTUNITIES_IN',
    OPPORTUNITIES_OUT: 'OPPORTUNITIES_OUT'
}