// Client Constants 
export const clientsConstants = {
    GET_CLIENTS_PAGINATE_REQUEST: 'GET_CLIENTS_PAGINATE_REQUEST',
    GET_CLIENTS_PAGINATE_SUCCESS: 'GET_CLIENTS_PAGINATE_SUCCESS',

    GET_ALL_CLIENTS_REQUEST: 'GET_ALL_CLIENTS_REQUEST',
    GET_ALL_CLIENTS_SUCCESS: 'GET_ALL_CLIENTS_SUCCESS',
    GET_ALL_CLIENTS_FAILURE: 'GET_ALL_CLIENTS_FAILURE',

    CREATE_CLIENT: 'CREATE_CLIENT',
    CREATE_CLIENT_FAILURE: 'CREATE_CLIENT_FAILURE',

    UPDATE_CLIENT: 'UPDATE_CLIENT',
    UPDATE_CLIENT_IMAGE: 'UPDATE_CLIENT_IMAGE',

    CLIENT_MODAL_OPEN: 'CLIENT_MODAL_OPEN',
    CLIENT_MODAL_CLOSE: 'CLIENT_MODAL_CLOSE',

    CLIENT_EDIT: 'CLIENT_EDIT',
    CLIENT_EDIT_FAILURE: 'CLIENT_EDIT_FAILURE',
}