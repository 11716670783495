export const jobApplyConstants = {

    GET_ALL_JOB_APPLY_REQUEST: 'GET_ALL_JOB_APPLY_REQUEST',
    GET_ALL_JOB_APPLY_SUCCESS: 'GET_ALL_JOB_APPLY_SUCCESS',

    CREATE_JOB_APPLY: 'CREATE_JOB_APPLY',
    CREATE_JOB_APPLY_FAILURE: 'CREATE_JOB_APPLY_FAILURE',

    UPDATE_JOB_APPLY: 'UPDATE_JOB_APPLY',
    UPDATE_JOB_FAILURE_APPLY: 'UPDATE_JOB_FAILURE_APPLY',

    JOB_APPLY_MODAL_OPEN: 'JOB_APPLY_MODAL_OPEN',
    JOB_APPLY_MODAL_CLOSE: 'JOB_APPLY_MODAL_CLOSE',

    JOB_APPLY_LIST_BY_JOB_ID_OPEN: 'JOB_APPLY_LIST_BY_JOB_ID_OPEN',
    JOB_APPLY_LIST_BY_JOB_ID_CLOSE: 'JOB_APPLY_LIST_BY_JOB_ID_CLOSE',

}