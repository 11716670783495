// Contact Constants
export const contactConstants = {
    GET_PRPFILE_ROUTE_ID: 'GET_PRPFILE_ROUTE_ID',

    GET_ALL_CONTACT_BY_ID_REQUEST: 'GET_ALL_CONTACT_BY_ID_REQUEST',
    GET_ALL_CONTACT_BY_ID_SUCCESS: 'GET_ALL_CONTACT_BY_ID_SUCCESS',

    CREATE_CONTACT: 'CREATE_CONTACT',
    CREATE_CONTACT_FAILURE: 'CREATE_CONTACT_FAILURE',

    CONTACT_CREATE_MODAL_OPEN: 'CONTACT_CREATE_MODAL_OPEN',
    CONTACT_CREATE_MODAL_CLOSE: 'CONTACT_CREATE_MODAL_CLOSE',

    UPDATE_CONTACT: 'UPDATE_CONTACT',
    UPDATE_CONTACT_FAILURE: 'UPDATE_CONTACT_FAILURE',

    GET_ALL_AGENCY_CONTACT_REQUEST: 'GET_ALL_AGENCY_CONTACT_REQUEST',
    GET_ALL_AGENCY_CONTACT_SUCCESS: 'GET_ALL_AGENCY_CONTACT_SUCCESS',
}